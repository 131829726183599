<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <base-card
      :height="value.prominent ? 450 : 350"
      color="grey lighten-1"
      dark
    >
      <v-img
        :src="require(`@/assets/img/gallery/${value.hero}`)"
        height="100%"
        gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)"
      >
        <v-row
          
          class="fill-height text-right ma-0"
        >
          <v-col cols="12">
            <v-chip
              label
              class="mx-0 mb-2 text-uppercase"
              color="grey darken-3"
              text-color="white"
              small
              @click.stop="$router.push('/imageviewer/' + value.large)"
            >
              Zoom In
            </v-chip>

            <h3 class="title font-weight-bold mb-2" style="color:grey;">
              {{ value.title }}
            </h3>

          </v-col>

          <v-col align-self="end">
            <v-chip
              class="text-uppercase ma-0"
              color="primary"
              label
              small
              @click.stop="gotoStory(value.hero)"
            >
            Read More
              <!-- <story :img_name="value.hero"/> -->
            </v-chip>
          </v-col>
        </v-row>
      </v-img>
    </base-card>
  </v-col>
</template>

<script>
  export default {
    name: 'FeedCard',

    components: {
      BaseCard: () => import('@/components/base/Card'),
      // ImageViewer: () => import('@/components/ImageViewer'),
    },

    methods: {
      gotoStory(story_id) {
        this.$router.push('/story/' + story_id)
      }
    },

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style scoped>
.v-image__image {
  transition: .3s linear;
}
</style>
<style scoped>
.title {
    /* font-family: "Caesar Dressing", Helvetica, Arial !important; */
    font-family: "Jim Nightshade", Helvetica, Arial !important;
    color: #aaaaaa !important;
}
</style>
